<template>
  <div class="page">
    <!-- 头部 -->
    <div class="dkzc-box">
      <div class="title">
        <i @click="$router.push('/index')" class="el-icon-arrow-left"></i>
        直播列表
        <i
          @click="$router.push('/liveBroadcastList')"
          class="el-icon-arrow-left"
        ></i>
        观看回放
      </div>
    </div>

    <!-- 播放器sbk -->
    <video
      id="player-container-id"
      preload="auto"
      playsinline
      webkit-playsinline
    ></video>

    <!-- 提示信息 -->
    <!-- <div v-show="isMask" class="mask">{{ maskMessage }}</div> -->
  </div>
</template>

<script>
export default {
  name: "liveBroadcastDetail",
  data() {
    return {
      token: localStorage.getItem("token"),
      player: null,
      url: "", // 直播地址
      // isMask: false, // 直播结束蒙层
      // maskMessage: "",
    };
  },

  created() {
    // 获取url
    this.url = this.$route.query.record;
  },

  mounted() {
    this.setPalerUrl();
    // 每隔1分钟请求一次
    setInterval(() => {
      this.liveKeep();
    }, 60000);
  },

  methods: {
    // 设置TCPlayer
    setPalerUrl() {
      this.player = TCPlayer("player-container-id", {
        autoplay: true,
        playbackRates: [0.5, 1, 1.5, 2],
      });
      this.player.src(this.url);
      // 视频播放出现错误时触发
      // this.player.on("error", (error) => {
      //   this.maskMessage = error.data.message;
      //   this.isMask = true;
      // });
    },

    // 直播间计时
    async liveKeep() {
      await this.$liveBroadcastApi.liveKeep({
        token: this.token,
        live_id: this.$route.query.id,
      });
    },
  },

  destroyed() {
    this.player.dispose();
  },
};
</script>

<style lang="scss" scopep>
.page {
  background: #f2f2f2;
  > .dkzc-box {
    width: 1200px;
    margin: 0 auto;
    margin-top: 30px;

    > .title {
      font-size: 18px;
      color: #333333;
      font-weight: 600;
      margin-bottom: 42px;

      > .el-icon-arrow-left {
        font-weight: 700;
        font-size: 20px;
        cursor: pointer;
      }
    }
  }

  .tcplayer {
    margin: 0 auto;
    min-width: 500px;
    max-width: 800px;
    min-height: 300px;
    max-height: 400px;
  }
  .vjs-button > .vjs-icon-placeholder:before {
    top: -17px;
  }
  .vjs-mute-control > .vjs-icon-placeholder:before {
    top: 0;
  }

  .mask {
    position: fixed;
    top: 340px;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #000;
    width: 800px;
    height: 400px;
    line-height: 400px;
    text-align: center;
    z-index: 10;
    color: #fff;
  }
}
</style>